import React from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { fuzzySearchTag } from '../Model/tag';
const InternalTagSelect = Select.ofType();
export function SingleTagSelect(props) {
    const renderItem = (tag, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const selected = props.selected && props.selected.id === tag.id;
        return (React.createElement(MenuItem, { active: false, selected: selected, icon: selected ? React.createElement("i", { className: "fal fa-check" }) : React.createElement("i", { className: "fal" }), key: tag.id, text: tag.name, onClick: handleClick, shouldDismissPopover: false }));
    };
    return (React.createElement(InternalTagSelect, { items: props.choices, itemRenderer: renderItem, noResults: React.createElement(MenuItem, { disabled: true, text: props.noResultsLabel }), onItemSelect: (tag) => props.onChange(tag), itemListPredicate: (query, tags) => fuzzySearchTag(tags, query), itemsEqual: (tag1, tag2) => tag1.id === tag2.id, popoverProps: {
            minimal: true,
            usePortal: false,
        } },
        React.createElement(Button, { icon: props.icon, rightIcon: "caret-down", text: props.selected ? props.selected.name : props.placeholder, intent: 'slate', outlined: true })));
}
