import { request } from '../utils/http';
import { AbstractController } from './abstract_controller';
/*
 * Store and refresh the global CSRF token.
 */
export default class default_1 extends AbstractController {
    doConnect() {
        window.Citipo.token = this.tokenValue;
        // Refresh every 15 minutes
        setInterval(() => this.refreshCsrfToken(this.refreshUrlValue), 900000);
    }
    refreshCsrfToken(refreshUrl) {
        request('GET', refreshUrl).then((res) => {
            window.Citipo.token = res.data.token;
        });
    }
}
default_1.values = {
    token: String,
    refreshUrl: String,
};
