import { InputGroup, Tag } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { SlateButton } from '../../Layout/SlateButton';
import { isQueryValid } from '../Model/queryParser';
export function CrmSearchField(props) {
    const [query, setQuery] = useState(props.value);
    useEffect(() => {
        let timer = undefined;
        // Trigger standard field changes with a slight delay
        // (query language changes are run using a button)
        if (!props.isQueryLanguage) {
            timer = setTimeout(() => props.onQueryChange(query), 300);
        }
        return () => clearTimeout(timer);
    }, [query]);
    return (React.createElement("div", { className: "crm-search-field" },
        React.createElement(InputGroup, { large: true, autoFocus: true, placeholder: props.isQueryLanguage ? props.labels.enterQuery : props.labels.placeholder, leftIcon: props.isQueryLanguage ? React.createElement("i", { className: "fal fa-code" }) : React.createElement("i", { className: "fal fa-search" }), rightElement: React.createElement(CrmSearchFieldFacets, Object.assign({}, props)), value: query, style: {
                fontFamily: props.isQueryLanguage ? 'monospace' : '',
                fontSize: props.isQueryLanguage ? '12px' : '',
                boxShadow: !props.isQueryLanguage || isQueryValid(query) ? '' : 'inset 0 0 0 2px rgb(205, 66, 70)',
            }, onChange: (e) => setQuery(e.currentTarget.value), onKeyDown: (e) => {
                if (e.key === 'Enter' && props.isQueryLanguage) {
                    props.onQueryChange(query);
                }
            } }),
        props.isQueryLanguage ? (React.createElement(SlateButton, { text: props.labels.runQuery, icon: React.createElement("i", { className: "far fa-play" }), className: "ml-2", minimal: true, outlined: false, onClick: () => props.onQueryChange(query) })) : (''),
        React.createElement(SlateButton, { text: props.isQueryLanguage ? props.labels.modeSimple : props.labels.modeAdvanced, icon: React.createElement("i", { className: "far fa-repeat" }), className: "ml-2", minimal: true, outlined: false, onClick: () => props.onModeChange(!props.isQueryLanguage) })));
}
function CrmSearchFieldFacets(props) {
    let values = [];
    for (let facetName in props.facetsView) {
        props.facetsView[facetName]
            .filter((v) => v.status !== 'inactive')
            .forEach((v) => values.push({
            facetName: facetName,
            formattedValue: props.facetsFormatters[facetName](v.value),
            status: v.status,
        }));
    }
    // Sort alphabetically
    values.sort((a, b) => a.formattedValue.localeCompare(b.formattedValue));
    const facetsIcons = {
        tags_names: 'far fa-tags',
        projects: 'far fa-cubes',
        status: 'far fa-users',
        area_country_code: 'far fa-globe-europe',
        area_province_name: 'far fa-map-marked-alt',
        area_district_name: 'far fa-map-marked-alt',
    };
    return (React.createElement(React.Fragment, null, values.map((v, key) => {
        if (v.facetName === 'projects' && !props.showProjectFacet) {
            return '';
        }
        return (React.createElement(Tag, { key: v.facetName + '-' + v.formattedValue + '-' + key, minimal: true, intent: v.status === 'exclude' ? 'danger' : 'none', icon: React.createElement("i", { className: facetsIcons[v.facetName] }) }, v.formattedValue));
    })));
}
