import React, { useState } from 'react';
import { Select2 } from '@blueprintjs/select';
import { SlateButton } from '../../../components/Layout/SlateButton';
import { InputGroup, MenuItem } from '@blueprintjs/core';
const ProjectSelect = Select2.ofType();
export default function (props) {
    const projects = props.projects;
    const [selectedProject, setSelectedProject] = useState(projects.length > 0 ? projects[0] : null);
    return (React.createElement("div", null,
        React.createElement("div", { className: "mb-1" }, props.labels.project),
        React.createElement("div", { className: "mb-4" },
            React.createElement(ProjectSelect, { items: projects, itemRenderer: (project, { handleClick, handleFocus, modifiers }) => (React.createElement(MenuItem, { key: project.uuid, text: project.name, selected: project.uuid === selectedProject.uuid, onClick: handleClick, onFocus: handleFocus, active: modifiers.active, disabled: modifiers.disabled })), onItemSelect: (project) => {
                    setSelectedProject(project);
                }, popoverProps: {
                    minimal: true,
                    placement: 'bottom-start',
                } },
                React.createElement(SlateButton, { text: selectedProject.name, rightIcon: "double-caret-vertical" }))),
        React.createElement("div", { className: "mb-3" }, props.labels.help),
        React.createElement("ul", null,
            React.createElement("li", { className: "mb-2" },
                props.labels.name,
                " ;"),
            React.createElement("li", { className: "mb-2" },
                props.labels.endpoint,
                " :",
                React.createElement("br", null),
                React.createElement(InputGroup, { defaultValue: selectedProject.endpoint, fill: true })),
            React.createElement("li", { className: "mb-2" },
                props.labels.events,
                " ;"),
            React.createElement("li", { className: "mb-2" },
                props.labels.http_method,
                " ;")),
        React.createElement("div", { className: "mt-4" }, props.labels.save_help)));
}
