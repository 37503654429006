import Fuse from 'fuse.js';
import { request } from '../../../../../utils/http';
export function fuzzySearchTag(tagsList, search) {
    if (!search) {
        return tagsList;
    }
    const fuse = new Fuse(tagsList, {
        includeScore: true,
        keys: ['name'],
    });
    return fuse.search(search).map((result) => result.item);
}
export class TagsRegistry {
    constructor(endpoint) {
        this.loaded = false;
        this.tags = {};
        this.endpoint = endpoint;
    }
    loadTags() {
        return new Promise((resolve) => {
            if (this.loaded) {
                resolve();
                return;
            }
            this.loaded = true;
            request('GET', this.endpoint).then((res) => {
                Object.keys(res.data).forEach((tagId) => {
                    this.tags[parseInt(tagId)] = {
                        id: parseInt(tagId),
                        name: res.data[tagId].name,
                        slug: res.data[tagId].slug,
                    };
                });
                resolve();
            });
        });
    }
    getTags() {
        let tags = [];
        for (let i in this.tags) {
            tags.push(this.tags[i]);
        }
        return tags;
    }
    getTag(id) {
        return this.tags[id] || null;
    }
}
