import React from 'react';
import { MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import { fuzzySearchTag } from '../Model/tag';
const InternalTagSelect = MultiSelect.ofType();
export function MultipleTagsSelect(props) {
    const renderItem = (tag, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const selected = props.selected.filter((t) => t.id === tag.id).length > 0;
        return (React.createElement(MenuItem, { active: false, selected: selected, icon: selected ? React.createElement("i", { className: "fal fa-check" }) : React.createElement("i", { className: "fal" }), key: tag.id, text: tag.name, onClick: handleClick, shouldDismissPopover: false }));
    };
    return (React.createElement(InternalTagSelect, { items: props.choices, selectedItems: props.selected.sort((a, b) => a.slug.localeCompare(b.slug)), onItemSelect: (tag) => {
            if (props.selected.filter((t) => t.id === tag.id).length > 0) {
                props.onTagDeselected(tag);
            }
            else {
                props.onTagSelected(tag);
            }
        }, itemRenderer: renderItem, tagRenderer: (tag) => tag.name, noResults: React.createElement(MenuItem, { disabled: true, text: props.noResultsLabel }), resetOnSelect: true, itemListPredicate: (query, tags) => fuzzySearchTag(tags, query), itemsEqual: (tag1, tag2) => tag1.id === tag2.id, fill: true, popoverProps: {
            minimal: true,
            usePortal: false,
        }, tagInputProps: {
            leftIcon: props.icon,
            placeholder: props.placeholder,
            onRemove: (_, index) => props.onTagDeselected(props.selected[index]),
        } }));
}
