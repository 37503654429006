import { AbstractController } from './abstract_controller';
export default class default_1 extends AbstractController {
    connect() {
        const refresh = () => {
            const trigger = this.triggerFieldTarget.value;
            this.formFilterTarget.style.display = trigger === 'new_form_answer' ? 'block' : 'none';
            this.tagFilterTarget.style.display = trigger === 'contact_tagged' ? 'block' : 'none';
        };
        refresh();
        this.triggerFieldTarget.addEventListener('change', refresh);
    }
}
default_1.targets = ['triggerField', 'formFilter', 'tagFilter'];
