import React, { useEffect, useState } from 'react';
import { dateFormat } from '../../../../utils/formatter';
import { request } from '../../../../utils/http';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { CrmItemAddress } from './CrmItemAddress';
import { createLink } from '../../../../utils/createLink';
export function CrmItemProfile(props) {
    return (React.createElement("div", { className: "crm-search-results-item-more" },
        React.createElement("div", { className: "crm-search-results-item-history" },
            React.createElement("strong", null, props.labels.historyTitle),
            React.createElement("div", null, props.labels.historyDescription),
            React.createElement(CrmListItemProfileHistory, Object.assign({}, props))),
        React.createElement("div", { className: "crm-search-results-item-profile" },
            React.createElement(CrmListItemProfileInformation, Object.assign({}, props)))));
}
function CrmListItemProfileHistory(props) {
    const item = props.result;
    const [history, setHistory] = useState(null);
    useEffect(() => {
        request('GET', createLink(props.links.history, { '-uuid-': item.id }), { responseType: 'text' }).then((res) => {
            setHistory(res.data);
        });
    }, []);
    if (!history) {
        return (React.createElement("div", { className: "mt-5 mb-5" },
            React.createElement(Spinner, { size: SpinnerSize.SMALL })));
    }
    return React.createElement("div", { className: "mt-3", dangerouslySetInnerHTML: { __html: history } });
}
function CrmListItemProfileInformation(props) {
    const item = props.result;
    let values = {
        [props.labels.newsletter]: (React.createElement("div", null,
            React.createElement("div", { className: 'crm-search-results-item-profile-subscription crm-search-results-item-profile-subscription-' +
                    (item.settings_receive_newsletters ? 'subscribed' : 'unsubscribed') },
                React.createElement("i", { className: 'far fa-' + (item.settings_receive_newsletters ? 'check' : 'times') }),
                props.labels.newsletter))),
        [props.labels.sms]: (React.createElement("div", null,
            React.createElement("div", { className: 'crm-search-results-item-profile-subscription crm-search-results-item-profile-subscription-' +
                    (item.settings_receive_sms ? 'subscribed' : 'unsubscribed') },
                React.createElement("i", { className: 'far fa-' + (item.settings_receive_sms ? 'check' : 'times') }),
                props.labels.sms))),
        [props.labels.calls]: (React.createElement("div", null,
            React.createElement("div", { className: 'crm-search-results-item-profile-subscription crm-search-results-item-profile-subscription-' +
                    (item.settings_receive_calls ? 'subscribed' : 'unsubscribed') },
                React.createElement("i", { className: 'far fa-' + (item.settings_receive_calls ? 'check' : 'times') }),
                props.labels.calls))),
    };
    if (item.contact_phone) {
        values[props.labels.phone] = (React.createElement("div", { className: "crm-search-results-item-profile-section" },
            React.createElement("div", { className: "crm-search-results-item-profile-section-label" }, props.labels.phone),
            React.createElement("div", { className: "crm-search-results-item-profile-section-value" }, item.contact_phone)));
    }
    if (item.profile_birthdate) {
        values[props.labels.birthdate] = (React.createElement("div", { className: "crm-search-results-item-profile-section" },
            React.createElement("div", { className: "crm-search-results-item-profile-section-label" }, props.labels.birthdate),
            React.createElement("div", { className: "crm-search-results-item-profile-section-value" }, dateFormat(item.profile_birthdate))));
    }
    if (item.address_street_line1 || item.address_city || item.address_zip_code) {
        values[props.labels.address] = (React.createElement("div", { className: "crm-search-results-item-profile-section" },
            React.createElement("div", { className: "crm-search-results-item-profile-section-label" }, props.labels.address),
            React.createElement("div", { className: "crm-search-results-item-profile-section-value" },
                React.createElement(CrmItemAddress, { item: item }))));
    }
    if (item.social_facebook ||
        item.social_twitter ||
        item.social_linked_in ||
        item.social_whatsapp ||
        item.social_telegram) {
        values[props.labels.socials] = (React.createElement("div", { className: "crm-search-results-item-profile-section" },
            React.createElement("div", { className: "crm-search-results-item-profile-section-label" }, props.labels.socials),
            React.createElement("div", { className: "crm-search-results-item-profile-section-socials" },
                item.social_facebook ? (React.createElement("a", { href: item.social_facebook, target: "_blank" },
                    React.createElement("i", { className: "fab fa-facebook" }))) : (''),
                item.social_twitter ? (React.createElement("a", { href: item.social_twitter, target: "_blank" },
                    React.createElement("i", { className: "fab fa-twitter" }))) : (''),
                item.social_linked_in ? (React.createElement("a", { href: item.social_linked_in, target: "_blank" },
                    React.createElement("i", { className: "fab fa-linkedin" }))) : (''),
                item.social_telegram ? (React.createElement("a", { href: 'https://t.me/' + item.social_telegram, target: "_blank" },
                    React.createElement("i", { className: "fab fa-telegram" }))) : (''),
                item.social_whatsapp ? (React.createElement("a", { href: 'https://wa.me/' + item.social_whatsapp, target: "_blank" },
                    React.createElement("i", { className: "fab fa-whatsapp" }))) : (''))));
    }
    return (React.createElement(React.Fragment, null, Object.keys(values).map((section) => (React.createElement("div", { key: section }, values[section])))));
}
