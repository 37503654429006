import React, { useState } from 'react';
export function QomonFormPreview(props) {
    var _a;
    return (React.createElement("span", { className: "contenteditor-custom-block-qomon" },
        React.createElement("span", { className: "contenteditor-data", style: { display: 'none' } }, JSON.stringify(props.data)),
        React.createElement("img", { src: "/res/qomon/icon-white.png", className: "contenteditor-custom-block-qomon-icon" }),
        React.createElement("span", { className: "contenteditor-custom-block-qomon-title" }, "Formulaire Qomon"),
        React.createElement("span", { className: "contenteditor-custom-block-qomon-help" }, ((_a = props.data) === null || _a === void 0 ? void 0 : _a.url) || 'Pas de formulaire configuré'),
        React.createElement("span", { className: "contenteditor-custom-block-qomon-noedit" }),
        React.createElement("button", { type: "button", className: "btn btn-light", onClick: props.openModal }, "Configurer le formulaire"),
        React.createElement("br", null),
        React.createElement("button", { type: "button", className: "btn btn-link", onClick: () => window.open('https://qomon.com', '_blank') }, "En savoir plus sur Qomon")));
}
export function QomonFormModal(props) {
    var _a;
    const [url, setUrl] = useState(((_a = props.initialData) === null || _a === void 0 ? void 0 : _a.url) || '');
    return (React.createElement("div", { className: "contenteditor-custom-block-modal" },
        React.createElement("div", { className: "contenteditor-custom-block-modal-overlay", onClick: props.closeModal }),
        React.createElement("div", { className: "contenteditor-custom-block-modal-content contenteditor-custom-block-qomon-modal-content" },
            React.createElement("div", { className: "mb-2" }, "Pour int\u00E9grer un formulaire Qomon dans votre contenu, vous devez tout d'abord cr\u00E9er un formulaire sur votre espace Qomon :"),
            React.createElement("div", { className: "text-center mb-4" },
                React.createElement("a", { href: "", className: "btn btn-secondary mb-1" },
                    React.createElement("i", { className: "far fa-plus mr-2" }),
                    "Cr\u00E9er un formulaire Qomon"),
                React.createElement("br", null),
                React.createElement("a", { href: "https://help.qomon.com/fr/articles/8042129-comment-utiliser-les-formulaires-en-ligne", className: "btn btn-link", target: "_blank" }, "Lire la documentation")),
            React.createElement("div", { className: "mb-1" }, "Une fois que votre formulaire est pr\u00EAt, indiquez ci-dessous son adresse URL :"),
            React.createElement("div", { className: "mb-4" },
                React.createElement("input", { type: "url", className: "form-control", value: url, onChange: (e) => setUrl(e.currentTarget.value), placeholder: "https://form.qomon.org/votre-formulaire/" })),
            React.createElement("div", { className: "text-center" },
                React.createElement("button", { type: "button", className: "btn btn-primary", onClick: () => {
                        props.onChange({ url: url });
                        props.closeModal();
                    } }, "Enregistrer")))));
}
