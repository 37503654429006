import React from 'react';
const STATUSES = {
    saving: {
        class: 'text-muted',
        icon: 'fal fa-circle-notch fa-spin',
    },
    saved: {
        class: 'text-success',
        icon: 'fal fa-check',
    },
    error: {
        class: 'text-danger',
        icon: 'fal fa-times',
    },
};
export function SaveStatus(props) {
    return (React.createElement("span", { className: STATUSES[props.status].class },
        React.createElement("i", { className: 'mr-2 ' + STATUSES[props.status].icon }),
        props.labels[props.status]));
}
