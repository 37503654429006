import React, { useMemo } from 'react';
import { render } from 'react-dom';
import { SaveStatus } from '../../../components/Layout/SaveStatus';
import { createUrlEncoded, request } from '../../../../utils/http';
import { Editor } from '../../../components/Editor/Editor';
export default function EmailCampaignEditor(props) {
    const statusNode = useMemo(() => document.querySelector(props.statusNode), [props.statusNode]);
    return (React.createElement(Editor, { displayMode: "email", projectId: props.projectId, design: props.design, mergeTags: props.mergeTags, appearance: props.appearance, labels: props.labels, onSave: (editor) => {
            render(React.createElement(SaveStatus, { status: "saving", labels: props.statusLabels }), statusNode);
            editor.exportHtml((data) => {
                const { design, html } = data;
                const response = request('POST', props.saveUrl, {
                    data: createUrlEncoded({
                        content: html ? html : '',
                        design: JSON.stringify(design),
                    }),
                });
                response.then(() => render(React.createElement(SaveStatus, { status: "saved", labels: props.statusLabels }), statusNode));
                response.catch(() => render(React.createElement(SaveStatus, { status: "error", labels: props.statusLabels }), statusNode));
            });
        }, onUpload: (editor, file, done) => {
            render(React.createElement(SaveStatus, { status: "saving", labels: props.statusLabels }), statusNode);
            const data = new FormData();
            data.append('file', file);
            const response = request('POST', props.uploadUrl, { data: data });
            response.catch(() => render(React.createElement(SaveStatus, { status: "error", labels: props.statusLabels }), statusNode));
            response.then((res) => {
                render(React.createElement(SaveStatus, { status: "saved", labels: props.statusLabels }), statusNode);
                done({ progress: 100, url: res.data.url });
            });
        } }));
}
