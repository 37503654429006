import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Button } from '@blueprintjs/core';
import { MultipleTagsSelector } from '../../Layout/TagsSelector/MultipleTagSelector';
export function CrmItemTags(props) {
    // Filter displayed names to hide potentially removed tags (names may not directly come from database)
    const tagsNames = props.result.tags_names
        .filter((name) => props.tagsNamesRegistry.indexOf(name) > -1)
        .sort((a, b) => a.localeCompare(b));
    return (React.createElement("div", { className: "crm-search-results-item-tags" },
        !props.isReadOnly ? (React.createElement(Popover2, { interactionKind: "click", placement: "bottom-start", content: React.createElement("div", { className: "crm-search-results-item-tags-edit" },
                React.createElement(MultipleTagsSelector, { tagsRegistry: props.tagsRegistry, defaultValue: props.tags ? props.tags.map((t) => t.id) : props.result.tags, onChange: props.onTagsChange, placeholder: props.labels.placeholder, noResultsLabel: props.labels.noTagsFound })) },
            React.createElement(Button, { outlined: true, small: true, className: "crm-search-results-item-tags-more" },
                React.createElement("i", { className: "far fa-edit" })))) : (''),
        React.createElement(CrmItemTagsList, { initialTagsNames: tagsNames, tags: props.tags !== null ? props.tags.sort((a, b) => a.slug.localeCompare(b.slug)) : null, labels: props.labels })));
}
function CrmItemTagsList(props) {
    // Tags not loaded from registry: use CRM document names
    if (null === props.tags) {
        if (0 === props.initialTagsNames.length) {
            return React.createElement("div", { className: "crm-search-results-item-tags-none" }, props.labels.noTags);
        }
        return (React.createElement(React.Fragment, null, props.initialTagsNames.map((name) => (React.createElement("div", { className: "crm-search-results-item-tag", key: name },
            React.createElement("i", { className: "far fa-check" }),
            name)))));
    }
    // Tags loaded from registry: use them
    if (0 === props.tags.length) {
        return React.createElement("div", { className: "crm-search-results-item-tags-none" }, props.labels.noTags);
    }
    return (React.createElement(React.Fragment, null, props.tags.map((tag) => (React.createElement("div", { className: "crm-search-results-item-tag", key: tag.id },
        React.createElement("i", { className: "far fa-check" }),
        tag.name)))));
}
