import React, { useMemo } from 'react';
import { SearchEngine } from '../../../components/Crm/Model/crm';
import { TagsRegistry } from '../../../components/Layout/TagsSelector/Model/tag';
import { request } from '../../../../utils/http';
import { createLink } from '../../../../utils/createLink';
import { CrmSearchEngine } from '../../../components/Crm/CrmSearchEngine';
export default function (props) {
    const searchEngine = useMemo(() => new SearchEngine(props.search.endpoint, props.search.token, props.search.index, props.search.project), [props.search.endpoint, props.search.token, props.search.index]);
    const tagsRegistry = useMemo(() => new TagsRegistry(props.tags.endpoint), [props.tags.endpoint]);
    return (React.createElement(CrmSearchEngine, Object.assign({ searchEngine: searchEngine, isProjectView: true, isReadOnly: props.isReadOnly, tagsRegistry: tagsRegistry, updateTagsCallback: (contactUuid, tags) => {
            request('POST', createLink(props.tags.updateEndpoint, { '-uuid-': contactUuid }), { data: tags });
        } }, props)));
}
