import 'jquery';
import React, { useState } from 'react';
import { Widget } from '@uploadcare/react-widget';
import { request } from '../../../../utils/http';
import { Intent, ProgressBar } from '@blueprintjs/core';
export default function (props) {
    const [fileUuid, setFileUuid] = useState(null);
    const [errors, setErrors] = useState([]);
    if (fileUuid) {
        return React.createElement(ProgressBar, { intent: Intent.PRIMARY });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Widget, { publicKey: props.uploadKey.publicKey, secureSignature: props.uploadKey.signature, secureExpire: props.uploadKey.expire, locale: window.Citipo.locale, systemDialog: true, doNotStore: true, onChange: (fileInfo) => {
                setFileUuid(fileInfo.uuid);
                setErrors([]);
                request('POST', props.prepareUrl + '&fileUuid=' + fileInfo.uuid + '&fileName=' + fileInfo.name)
                    .then((res) => {
                    window.location = res.data.redirectUrl;
                })
                    .catch(() => {
                    setFileUuid(null);
                    setErrors([props.errorLabel]);
                });
            } }),
        errors.map((error) => (React.createElement("div", { className: "text-danger mt-2" }, error)))));
}
