import React from 'react';
import { render } from 'react-dom';
import { AbstractController } from './abstract_controller';
import { QomonPetitionModal, QomonPetitionPreview } from '../react/components/Editor/Blocks/QomonPetition';
import { QomonFormModal, QomonFormPreview } from '../react/components/Editor/Blocks/QomonForm';
const customBlocks = {
    QomonPetition: {
        preview: QomonPetitionPreview,
        modal: QomonPetitionModal,
    },
    QomonForm: {
        preview: QomonFormPreview,
        modal: QomonFormModal,
    },
};
export default class default_1 extends AbstractController {
    doConnect() {
        var _a;
        if (typeof customBlocks[this.nameValue] === 'undefined') {
            throw new Error('Invalid custom block name: ' + this.nameValue);
        }
        const editorId = this.element.closest('.editor-content').getAttribute('id');
        const customBlock = customBlocks[this.nameValue];
        const renderPreview = (data) => {
            render(React.createElement(customBlock.preview, {
                openModal: openModal,
                closeModal: closeModal,
                data: data,
            }), this.element);
        };
        const openModal = () => {
            var _a;
            render(React.createElement(customBlock.modal, {
                closeModal: closeModal,
                onChange: (data) => {
                    renderPreview(data);
                    window.editors[editorId]._handleCodeBuildChange();
                },
                initialData: JSON.parse(((_a = this.element.querySelector('.contenteditor-data')) === null || _a === void 0 ? void 0 : _a.innerHTML) || 'null'),
            }), document.getElementById('contenteditor-customblock-editor'));
        };
        const closeModal = () => {
            render(React.createElement("div", null), document.getElementById('contenteditor-customblock-editor'));
        };
        renderPreview(JSON.parse(((_a = this.element.querySelector('.contenteditor-data')) === null || _a === void 0 ? void 0 : _a.innerHTML) || 'null'));
    }
}
default_1.values = { name: String };
