import React, { useMemo } from 'react';
import { SearchEngine } from '../../../components/Crm/Model/crm';
import { TagsRegistry } from '../../../components/Layout/TagsSelector/Model/tag';
import { request } from '../../../../utils/http';
import { createLink } from '../../../../utils/createLink';
import { CrmSearchEngine } from '../../../components/Crm/CrmSearchEngine';
export default function (props) {
    const searchEngine = useMemo(() => new SearchEngine(props.search.endpoint, props.search.token, props.search.index), [props.search.endpoint, props.search.token, props.search.index]);
    if (props.tags.defaultFilter) {
        searchEngine.includeFilter('tags_names', props.tags.defaultFilter);
    }
    const tagsRegistry = useMemo(() => new TagsRegistry(props.tags.endpoint), [props.tags.endpoint]);
    return (React.createElement(CrmSearchEngine, Object.assign({ searchEngine: searchEngine, isProjectView: false, isReadOnly: false, tagsRegistry: tagsRegistry, updateTagsCallback: (contactUuid, tags) => {
            request('POST', createLink(props.tags.updateEndpoint, { '-uuid-': contactUuid }), { data: tags });
        } }, props)));
}
