import React from 'react';
import { countryName } from '../../../../utils/formatter';
export function CrmItemAddress(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, props.item.address_street_line1),
        React.createElement("div", null, props.item.address_street_line2),
        React.createElement("div", null,
            props.item.address_zip_code,
            " ",
            props.item.address_city),
        React.createElement("div", null, props.item.address_country ? countryName(props.item.address_country) : '')));
}
