import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { SingleTagSelect } from './Selector/SingleTagSelect';
export function SingleTagSelector(props) {
    const [selectedTagId, setSelectedTagId] = useState(null);
    const [tagsRegistryLoaded, setTagsRegistryLoaded] = useState(false);
    const handleChange = (tagId) => {
        setSelectedTagId(tagId);
        props.onChange && props.onChange(props.tagsRegistry.getTag(tagId));
    };
    // Fetch available tags on mount
    useEffect(() => {
        props.tagsRegistry.loadTags().then(() => {
            setTagsRegistryLoaded(true);
            // If a default tag is provided, set it as selected
            if (props.defaultValue) {
                handleChange(props.defaultValue);
            }
        });
    }, []);
    if (!tagsRegistryLoaded) {
        return (React.createElement(Button, { icon: React.createElement("i", { className: "far fa-tag" }), rightIcon: React.createElement("i", { className: "far fa-circle-notch fa-spin" }), text: props.defaultValue ? props.defaultValueName || props.placeholder : props.placeholder, intent: 'slate', disabled: true, outlined: true }));
    }
    return (React.createElement(SingleTagSelect, { choices: props.tagsRegistry.getTags(), selected: selectedTagId ? props.tagsRegistry.getTag(selectedTagId) : null, onChange: (tag) => handleChange(tag.id), placeholder: props.placeholder, noResultsLabel: props.noResultsLabel, icon: React.createElement("i", { className: "far fa-tag" }) }));
}
