import React from 'react';
import { CrmItem } from './CrmItem';
import { Button, Menu, MenuItem, NonIdealState, Spinner } from '@blueprintjs/core';
import { SlateButton } from '../../Layout/SlateButton';
import { Popover2 } from '@blueprintjs/popover2';
export function CrmList(props) {
    if (props.results === null) {
        return React.createElement(Spinner, { className: "crm-search-results-list-loading" });
    }
    if (props.results.length === 0) {
        return (React.createElement("div", { className: "crm-search-results-list-noresult" },
            React.createElement(NonIdealState, { icon: "search", title: props.listLabels.noResultsTitle, description: props.listLabels.noResultsDescription })));
    }
    return (React.createElement("div", { className: props.resultsLoading ? 'crm-search-results-loading' : '' },
        React.createElement("div", { className: "crm-search-results-head" },
            React.createElement("div", { className: "crm-search-results-head-sort" },
                React.createElement(Popover2, { interactionKind: "click", placement: "bottom-start", content: React.createElement(CrmListSortMenu, { selectedField: props.sortField, selectedType: props.sortType, labels: props.listLabels, onChange: props.onSortChange }) },
                    React.createElement(CrmListSortButton, { selectedField: props.sortField, selectedType: props.sortType, labels: props.listLabels }))),
            React.createElement("div", { className: "crm-search-results-head-location" }, props.listLabels.location),
            React.createElement("div", { className: "crm-search-results-head-activity" }, props.listLabels.createdAt),
            React.createElement("div", { className: "crm-search-results-head-status" }, props.listLabels.status)),
        props.results.map((result) => (React.createElement(CrmItem, Object.assign({ key: result.id, result: result, isOpen: props.openedItems.indexOf(result.id) > -1, onClick: () => props.onItemClick(result) }, props)))),
        props.hasMoreResults ? (React.createElement(SlateButton, { onClick: props.onLoadMoreClick, disabled: props.isLoadingMore, icon: props.isLoadingMore ? React.createElement("i", { className: "far fa-spin fa-circle-notch" }) : null, className: "crm-search-results-list-loadmore" }, props.listLabels.loadMore)) : ('')));
}
function CrmListSortButton(props) {
    const fieldsLabels = {
        created_at: props.labels.sortByDate,
        profile_first_name: props.labels.sortByFirstName,
        profile_last_name: props.labels.sortByLastName,
        email: props.labels.sortByEmail,
    };
    return (React.createElement(Button, { outlined: true, small: true, className: "crm-search-results-head-sort-button", icon: React.createElement("i", { className: "far fa-sort-alt" }) },
        props.labels.sortBy + ' ',
        fieldsLabels[props.selectedField] + ' ',
        "(",
        props.selectedType === 'asc' ? props.labels.sortByAsc : props.labels.sortByDesc,
        ")"));
}
function CrmListSortMenu(props) {
    return (React.createElement(Menu, null,
        React.createElement(MenuItem, { text: props.labels.sortByDate + ' (' + props.labels.sortByDesc + ')', onClick: () => props.onChange('created_at', 'desc') }),
        React.createElement(MenuItem, { text: props.labels.sortByDate + ' (' + props.labels.sortByAsc + ')', onClick: () => props.onChange('created_at', 'asc') }),
        React.createElement(MenuItem, { text: props.labels.sortByFirstName + ' (' + props.labels.sortByDesc + ')', onClick: () => props.onChange('profile_first_name', 'desc') }),
        React.createElement(MenuItem, { text: props.labels.sortByFirstName + ' (' + props.labels.sortByAsc + ')', onClick: () => props.onChange('profile_first_name', 'asc') }),
        React.createElement(MenuItem, { text: props.labels.sortByLastName + ' (' + props.labels.sortByDesc + ')', onClick: () => props.onChange('profile_last_name', 'desc') }),
        React.createElement(MenuItem, { text: props.labels.sortByLastName + ' (' + props.labels.sortByAsc + ')', onClick: () => props.onChange('profile_last_name', 'asc') }),
        React.createElement(MenuItem, { text: props.labels.sortByEmail + ' (' + props.labels.sortByDesc + ')', onClick: () => props.onChange('email', 'desc') }),
        React.createElement(MenuItem, { text: props.labels.sortByEmail + ' (' + props.labels.sortByAsc + ')', onClick: () => props.onChange('email', 'asc') })));
}
