import React from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import Fuse from 'fuse.js';
function fuzzySearchAuthors(availableAuthors, search) {
    const maxResults = 5;
    if (!search) {
        return availableAuthors.slice(0, maxResults);
    }
    const fuse = new Fuse(availableAuthors, {
        includeScore: true,
        keys: ['fullName'],
    });
    return fuse
        .search(search)
        .slice(0, maxResults)
        .map((result) => result.item);
}
const InternalTagSelect = MultiSelect.ofType();
export default function ImportAuthorsSelector(props) {
    const [selectedIds, setSelectedIds] = React.useState([]);
    const handleChange = (ids) => {
        setSelectedIds(ids);
    };
    const renderItem = (author, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const selected = selectedIds.filter((id) => id === author.id).length > 0;
        return (React.createElement(MenuItem, { active: false, selected: selected, icon: selected ? React.createElement("i", { className: "fal fa-check" }) : React.createElement("i", { className: "fal" }), key: author.id, text: author.fullName, onClick: handleClick, shouldDismissPopover: false }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "hidden", name: props.fieldName, value: selectedIds.join(',') }),
        React.createElement(InternalTagSelect, { items: props.choices, selectedItems: props.choices.filter((author) => selectedIds.includes(author.id)), onItemSelect: (author) => {
                if (selectedIds.includes(author.id)) {
                    handleChange(selectedIds.filter((id) => id !== author.id));
                }
                else {
                    handleChange(selectedIds.slice().concat([author.id]));
                }
            }, itemRenderer: renderItem, tagRenderer: (author) => author.fullName, noResults: React.createElement(MenuItem, { disabled: true, text: "Aucun r\u00E9sultat" }), resetOnSelect: true, itemListPredicate: (query, authors) => fuzzySearchAuthors(authors, query), itemsEqual: (author1, author2) => author1.id === author2.id, fill: true, popoverProps: {
                minimal: true,
                usePortal: false,
            }, tagInputProps: {
                leftIcon: React.createElement("i", { className: "far fa-user" }),
                placeholder: 'Rechercher un(e) auteur(e)',
                onRemove: (_, index) => handleChange(selectedIds.filter((id) => id !== selectedIds[index])),
            } })));
}
