import React, { useState } from 'react';
import { Button, InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import Fuse from 'fuse.js';
export function CrmFacetSelector(props) {
    const [valueFilter, setValueFilter] = useState('');
    const sortedValues = props.values.sort((a, b) => {
        // First sort by status
        const STATUS_ORDER = { include: 2, exclude: 1, inactive: 0 };
        if (a.status !== b.status) {
            return STATUS_ORDER[a.status] > STATUS_ORDER[b.status] ? -1 : 1;
        }
        // Then alphabetically
        return props.formatValue(a.value).localeCompare(props.formatValue(b.value));
    });
    const displayedValues = fuzzySearchFacetValue(sortedValues, valueFilter);
    return (React.createElement("div", { className: "crm-search-results-facet" },
        React.createElement("div", { className: "crm-search-results-facet-title" }, props.facetsLabels[props.facet]),
        sortedValues.length > 10 ? (React.createElement("div", { className: "crm-search-results-facet-search" },
            React.createElement(InputGroup, { value: valueFilter, onChange: (e) => setValueFilter(e.currentTarget.value), placeholder: props.facetsLabels.valueSearch, rightElement: valueFilter ? (React.createElement(Button, { outlined: true, style: { border: 0 }, onClick: () => setValueFilter('') },
                    React.createElement("i", { className: "far fa-times" }))) : (React.createElement("div", null)) }))) : (''),
        displayedValues.slice(0, 10).map((row) => {
            const label = props.formatValue(row.value);
            if (label === '') {
                return '';
            }
            return (React.createElement("div", { className: 'crm-search-results-facet-value crm-search-results-facet-value-' + row.status, key: row.value, onClick: () => {
                    if (row.status === 'inactive') {
                        props.onFilterInclude(row.value);
                    }
                    else {
                        props.onFilterCancel(row.value);
                    }
                } },
                React.createElement("div", { className: "crm-search-results-facet-value-label" }, props.formatValue(row.value)),
                React.createElement("div", { className: "crm-search-results-facet-value-count" }, row.count),
                React.createElement("div", { className: "crm-search-results-facet-value-actions", onClick: (e) => e.stopPropagation() },
                    React.createElement(Popover2, { content: React.createElement(CrmFacetMenu, Object.assign({ value: row.value, status: row.status }, props)), position: "top-left" },
                        React.createElement(Button, { outlined: true, small: true, className: "crm-search-results-facet-value-actions-button" },
                            React.createElement("i", { className: "far fa-ellipsis-h" }))))));
        })));
}
function CrmFacetMenu(props) {
    return (React.createElement(Menu, null,
        props.status !== 'include' ? (React.createElement(MenuItem, { text: props.facetsLabels.includeFilter, onClick: () => props.onFilterInclude(props.value) })) : (''),
        props.status !== 'exclude' ? (React.createElement(MenuItem, { text: props.facetsLabels.excludeFilter, onClick: () => props.onFilterExclude(props.value) })) : (''),
        props.status !== 'inactive' ? (React.createElement(MenuItem, { text: props.facetsLabels.cancelFilter, onClick: () => props.onFilterCancel(props.value) })) : ('')));
}
function fuzzySearchFacetValue(values, search) {
    if (!search) {
        return values;
    }
    const fuse = new Fuse(values, {
        includeScore: true,
        keys: ['value'],
    });
    return fuse.search(search).map((result) => result.item);
}
